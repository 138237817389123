import styled from "@emotion/styled"
import React from "react"

import Hexie from "../components/Hexie"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import mq from "../utils/mediaQuery"

const Container = styled.div`
  ${mq({
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  })};
`
const Gallery = () => {
  const content = []
  for (let i = 0; i < 256; i++) {
    content.push(<Hexie key={i} colorNumber={i} />)
  }
  return (
    <>
      <SEO title="cryptohexies" />
      <Layout title="cryptohexies">
        <Container>{content}</Container>
      </Layout>
    </>
  )
}

export default Gallery
